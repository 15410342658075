.button {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    // text-transform: uppercase;
    color: $white;
    padding: 15px 20px;
    cursor: pointer;
    display: inline-block;
    border-radius: 100px;

    &.primary_blue {
        background-color: $primary-color;
        border: solid 1px $primary-color;
    }

    &.white {
        background-color: $white;
        border: solid 2px $secondary-color1;
        color: $secondary-color1;
    }
}