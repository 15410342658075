.resource-content {
    padding-top: 80px;

    @include breakpoint(tablet){
        padding-top: 20px;
    }

    .indent {
        @extend %indent;
    }

    h4 {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
    }

    h2 {
        font-weight: 600;
        margin-top: 10px;
        font-size: 38px;
        line-height: 48px;
        color: $black;
        margin-bottom: 50px;

        @include breakpoint(tablet){
            padding: 0 20px;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 10px;
        }
    }

    h3 {
        font-family:  Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 55px;
    }

    p {
        margin: 0 0 20px 0;
        font-size: 18px;
        line-height: 32px;

    }

    .fact-sheets, .resource {
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @extend %indent;

        .item {
            width: calc(25% - 20px);
            background: $white;
            border: 2px solid $border-light-color;
            margin: 0 10px;
            padding: 20px;
            color: $primary-color;

            h3 {
                color: $secondary-color2;
                font-weight: 600;
                font-size: 48px;
                line-height: 58px;
                font-family:  Arial, Helvetica, sans-serif;
                margin-bottom: 16px;
            }

            h4 {
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 16px;
            }

            p {
                color: $body-text;
            }
            
            &:nth-child(even){
                color: $white;
                background: $primary-color;
                border: 2px solid $primary-color;

                h3 {
                    color: $white;
                }

                a, p {
                    color: $white;
                }
            }
            a {
                display: block;
                text-decoration: none;
                text-align: right;
                &:after{
                    @extend %right-arrow;
                }
            }
        }

        @include breakpoint(tablet){
            .item {
                width: calc(50% - 20px);
                margin: 10px;
            }
        }

        @include breakpoint(mobile){
            .item {
                width: calc(100% - 20px);
                margin: 10px;
            }
        }
    }

    // resources column override
    .resources {
        .item {
            width: calc(33% - 20px);
        }

        @include breakpoint(tablet){
            .item {
                width: calc(50% - 20px);
                margin: 10px;
            }
        }

        @include breakpoint(mobile){
            .item {
                width: calc(100% - 20px);
                margin: 10px;
            }
        }

    }

    .videos{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @extend %indent;

        .item {
            width: 100%; margin-bottom: 40px; display: flex; 
            flex-direction: row; justify-content: space-between; 
            border-bottom: 1px solid $border-dark-color; padding-bottom: 25px; align-items: center;

            .thumbnail{ 
                width: 30%; position: relative;
                padding-right: 10px; 

                img {
                    width: 100%;
                }

                .playBtn { position: absolute; cursor: pointer;
                    // background: url('../../assets/icons/video_play_icon.png') no-repeat 0 0;
                    top: 50%;left: 50%; 
                    margin-top: -50px; margin-left: -50px; 
                    width: 75px; height: 7375
                    5px; 
                    // background-size: 75px; 
                    z-index: 1;
                }

                @include breakpoint(mobile){
                    width: 100%;
                }
            }
            .info{ width: 70%; font-size: 14px; color: $body-text;
                h3{ font-family:  Arial, Helvetica, sans-serif; font-size: 14px; color: #aaaaaa; margin-bottom: 15px;}
                h4{ font-family:  Arial, Helvetica, sans-serif; font-size: 18px; text-transform: capitalize; color: $body-text; margin-bottom: 15px; 
                    @include breakpoint(mobile) {
                        line-height: normal;
                    }
                }
                p{ 
                    font-size: 14px;
                    line-height: 24px;
                }
                a {
                    display: block;
                    text-decoration: none;
                    &:after{
                        @extend %right-arrow;
                    }
                }
                @include breakpoint(mobile){
                    width: 100%;
                }
            }

                @include breakpoint(mobile){
                    display: block;
                    width: 100%;
                }
        }

        @include breakpoint(mobile){
        }
    }

    .faqs {
        padding: 0 80px 80px 80px;
        h4 {
            color: $primary-color;
            margin-top: 50px;
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            text-transform: none;
            margin-bottom: 2rem;
        }
        @include breakpoint(tablet){
            padding: 20px;
        }
    }

    a {
        text-decoration: underline;
    }

}