@import "./reset";
@import "./responsive";
@import "./variables";

.page-content {
    display: block;
    max-width: 1280px;
    margin: 0 auto;
    font-family:  Arial, Helvetica, san-serif;
    color: $body-text;
    position: relative;

    hr {
        height: 0px;
        border-top: solid 1px $body-text;
        margin-left: 85px;
        margin-right: 85px;
    }
}

.inner-content {
    @extend %extra-padding;
}

a {
    text-decoration: none;
    color: $primary-color;

    &:visited {
        color: $primary-color;
    }

    &:hover {
        color: $secondary-color1;
    }
}

.back {
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: 600;
    a {
        color: $primary-color;
    }
}

.page-label {
    font-size: 16px;
    color: $primary-color;
    font-weight: 600;
    padding-bottom: 10px;
}

.two-cols {
    display: flex;

    .col-1 {
        &:first-of-type {
            margin-right: 20px;
        }
    }

    img {
        align-items: center;
        margin: auto;
        min-width: 400px;
        width: 100%;
    }

    @include breakpoint(tablet){
        display: block;
        margin-bottom: 30px;

        .col-1 {
            &:first-of-type {
                margin-right: 0px;
            }
        }
    }

    @include breakpoint(mobile){
        img {
            max-width: 300px;
        }
    }
}

/** base headeline font size - overwrite in each page if necessary */

h1 {
    font-size: 44px;
    font-weight: 600;

    @include breakpoint(tablet){
        font-size: 34px;
    }
}

h2 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 30px;

    @include breakpoint(tablet){
        font-size: 28px;
    }
}

h3 {
    font-size: 28px;
    font-weight: 600;

    @include breakpoint(tablet){
        font-size: 22px;
    }
}

h4 {
    font-size: 24px;
    font-weight: 600;

    @include breakpoint(tablet){
        font-size: 18px;
    }
}

h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;

    @include breakpoint(tablet){
        font-size: 16px;
    }
}

h6 {
    font-size: 16px;
    font-weight: 600;

    @include breakpoint(tablet){

    }
}


/* common set up that are used multiple places
   use extend */

%indent {
    padding: 0 80px;
        
    @include breakpoint(tablet){
        padding:0 20px;
    }
}

%extra-padding{ 
    padding: 40px 80px; 

    @include breakpoint(mobile){
        padding: 40px; 
    }

    &.no-bottom-padding {
        padding-bottom: 0;
    }
}

%right-arrow {
    padding-left: 10px; /* Add a little space between text and arrow */
    content: "\2192";
}