@import "../responsive";

.get-involved {

    p {
        font-size: 18px;
        line-height: 32px;
        // opacity: 0.6;
        margin: 20px 0px 40px;
        @include breakpoint(tablet){
            margin: 0 20px 40px 20px;
        }

        a {
            text-decoration: underline;
        }
        em {
            font-style: italic;
        }
    }
    
    ul {
        list-style: none;

        li {
            margin-left: 0px;
            margin-bottom: 20px;
            a { 
                font-size: 18px; 
                font-weight: bold;
                display: block;
                text-decoration: none;
                padding-top: 10px;
                sup{
                    vertical-align: super; font-size: smaller;
                }
            }
        }
    }



    .form-container {
        padding: 40px 80px;
        display: flex;
        flex-direction: row;

        form{
            width: 100%;float: left; 
        }

        .businesses {
            width: 100%;
        }
    }

    .individuals, .businesses{
        margin: 30px 0px;
    }

    .indent {
        padding: 0 80px;
        
        @include breakpoint(tablet){
            padding:0 20px;
        }
    }
}