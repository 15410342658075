.impact-content {
    

    @extend %extra-padding;

    h4 {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #000000;
    }

    h5 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
    }

    h2 {
        font-weight: 600;
        margin-top: 10px;
        font-size: 48px;
        line-height: 58px;
        color: #000000;
        margin-bottom: 50px;
    }

    p {
        margin: 0 0 30px 0;
        font-size: 18px;
        line-height: 32px;

        a {
            text-decoration: underline;
        }

    }

}