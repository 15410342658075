$black: #000000;
$white: #ffffff;

/** branding colors */
$primary-color: #0884A5;
$secondary-color1: #24C6E0;
$secondary-color2: #005870;

$body-text: $black;
$border-light-color: #F2F2F2;
$border-dark-color: #979797;
