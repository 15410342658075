@import "../responsive";

.info-pane {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 40px 85px 20px 85px;
    .left {
        width: 390px;
        flex-shrink: 0;
        text-align: center;
        
        .button{margin-left: -20px; margin-bottom: 20px;}

        .logo-container {
            width: 150px;
            margin: 20px auto;
            display: block;
        }

        @include breakpoint(tablet){
 
           width : 100%;
        }
    }
    .main {
        p {
            font-size: 18px;
            line-height: 32px;
            color: $body-text; 
            padding-bottom: 40px;
        }
    }

    @include breakpoint(tablet){
        display: block;
        padding: 20px;
    }
}