@import "../responsive";

.fixed-header {
    height: 150px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 60px 66px;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    // top: 0;
    // left: 0;
    // z-index: 10;
    color: $white;

    .mobile-nav {
        height: 25px;
        width: 25px;
        svg {
            // fill: $white;
            width: 25px;
            height: 25px;
        }
        display: none;

        @include breakpoint(tablet){
            display: block;
        }

        margin-right: 30px;
        cursor: pointer;
    }

    &.light {
        color: $black;

        .navigation {
            color: $black;

            .link a {
                color: $black;
                &:visited {
                    color: $black;
                }
            }
        }
        .mobile-nav {
            svg {
                fill: $black;
                width: 25px;
                height: 25px;
            }
        }
    }


    .logo {
        .logo-container {
            width: 150px;
            
        @include breakpoint(mobile){
            margin: auto;
        }
        }
        @include breakpoint(tablet){
            flex-grow: 1;
        }
    }

    .cta {
        @include breakpoint(mobile){
            display: none;
        }
    }

    .navigation {
        text-align: center;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $body-text;


        .link {
            position: relative;
            padding: 0 15px;

            a {
                color: $body-text;
                &:visited {
                    color: $body-text;
                }
            }

            &.selected {
                font-weight: normal;
                color: $secondary-color1;

                a {
                    color: $secondary-color1;
                    
                    &:visited {
                        color: $secondary-color1;
                    }
                }
            }

            &.close {
                display: none;
                svg {
                    width: 15px;
                    height: 15px;
                    fill: $black;
                }
            }
        }

        @include breakpoint(tablet){
            padding-top: 20px;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            background: $white;
            width: 100%;

            .link {
                display: block;
                font-size: 18px;
                padding:20px 20px;

                &.close {
                    display: block;
                    cursor: pointer;
                }
            }

            &.nav-open {
                display: block;
                z-index: 10;
            }
        }
    }
}