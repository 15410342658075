.pandemic-intro {
    // padding-top: 180px;


    p {
        font-size: 18px;
        line-height: 32px;
        opacity: 0.6;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .background{
        background: #F9F9F9;
    }
    .extra-padding{ 
        @extend %extra-padding;
    }
    .dashboards {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include breakpoint(mobile){
            flex-wrap: wrap;
        }

        .item {

            width: 33%;
            flex-shrink: 0;
            padding: 0 20px;

            img{ width: 100%; height: auto; }
            a { 
                font-size: 14px; 
                font-weight: bold;
                display: block;
                text-decoration: none;
                padding-top: 10px; 
                &:last-child {
                    &:after {
                        @extend %right-arrow;
                }
            }
            }

            &.hide {
                display: none;
            }

            @include breakpoint(mobile){
                width: 100%;
                padding: 20px;
            }

        }
        h4 {
            // font-weight: 600;
            // font-size: 18px;
            // line-height: 16px;
            padding: 15px 0px 20px;
            text-transform: capitalize;
        }
        p{
            font-size: 16px;
            margin: 0px;
        }
        
    }

    .indent {
        padding: 0 80px;
        
        @include breakpoint(tablet){
            padding:0 20px;
        }
    }

    h4 {
        line-height: 34px;
        min-height: 102px;
    }

    .lead {
        margin: 60px 0;
        height: 480px;
        background: url('../../assets/backgrounds/indiaPandemic.jpg') no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $white;

        h3 {
            font-size: 67px;
            line-height: 58px;
        }
        
        h4 {
            font-family:  Arial, Helvetica, sans-serif;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $white;
            
        }

        @include breakpoint(mobile){
            width: 100%;
            margin: 0px;
            height: auto;
            min-height: 200px;
            background-size: cover;
        }
    }

    .details {
        display: flex;
        flex-direction: row;

        .header {
            font-size: 36px;
            line-height: 46px;
            width: 40%;
            flex-shrink: 0;
        }

        @include breakpoint(tablet){
            display: block;
            .header {
                width: 100%;
                padding: 20px 0;
            }
        }

    }

    .data-frame {
        padding: 80px;
        display: flex;
        flex-direction: row;

        .intro {
            margin-left: 35px;
            width: 25%;
            flex-shrink: 0;
            h3 {
                font-weight: 600;
                font-size: 36px;
                line-height: 46px;
            }
            &.mobile{display: none;}
        }

        .data {
            flex-grow: 1;
        }

        &.background {
            background: #F9F9F9;
            .intro { 
                margin-right: 35px; 
                margin-left: 0px;
            }
        }
        @include breakpoint(tablet){
            .intro{
                &.mobile{ display: block;}
                &.rDesktop{ display: none;}
            }
        }

        @include breakpoint(tablet){
            display: block;
            padding: 20px;
            .intro{ 
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }
    
        }
    }

    .iframe-responsive-container {
        position: relative;
        overflow: hidden;
        padding-bottom: 56.25%;
        padding-top: 20px;

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
   
}