footer {
    height: 233px;
    background: #F2F2F2;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    padding-top: 50px;

    .logo-container {
        width: 200px;
        display: block;
        margin: 10px auto;
    }
}