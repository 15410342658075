@import "../responsive";

.press-release-content {
    // padding-top: 180px;

    .indent {
        padding: 0 80px;

        @include breakpoint(tablet){
            padding: 0 20px;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 32px;
        color: $black;
        font-family:  Arial, Helvetica, sans-serif;
        font-weight: 600;
        margin-bottom: 30px;

        @include breakpoint(tablet){
            font-size: 18px;
            line-height: 32px;
        }
    }

    h5 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        &.back {
            font-size: 14px;
            line-height: 16px;
            a {
                color: #E3364E;
            }
        }
    }

    h2 {
        font-weight: 600;
        margin-top: 10px;
        font-size: 48px;
        line-height: 58px;
        color: $black;
        margin-bottom: 30px;
        @include breakpoint(tablet){
            font-size: 18px;
            line-height: 32px;
        }
    }

    p {
        margin: 0 0 50px 0;
        font-size: 18px;
        line-height: 32px;

        a {
            text-decoration: underline;
        }
        em {
            font-style: italic;
        }
        ul {
            list-style: circle;

            li {
                margin-left: 20px;
            }
        }

    }

}