@import "../responsive";

.mission-statement {
    margin: 0;
    padding: 40px 85px;

    p {
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 20px;

        a {
            text-decoration: underline;
        }
    }

    h3 {
        font-weight: 600;
        line-height: 74px;
        margin-bottom: 60px;
    }

    .copy {
        display: flex;
        flex-direction: row;

        .item {
            width: 33%;
            flex-shrink: 0;
            padding: 0 20px;

            &:first-of-type {
                padding-left: 0px;
            }

            &:last-of-type {
                padding-right: 0px;
            }
        }
        h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
        }
        
    }

    @include breakpoint(tablet){
        margin: 20px 0;
        padding: 20px;


        h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 37px;
            margin-bottom: 30px;
            width: 100%;
        }

        .copy {
            display: block;
            .item {
                width: 100%;
                padding: 0 0 30px 0;
            }
        }
    }
}