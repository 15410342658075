@import "../responsive";

.partners {
    @extend %extra-padding;
    padding-top: 0;

    h4 {
        font-size: 24px;
        line-height: 36px;
        font-weight: normal;
        padding-bottom: 30px;
    }

    ul {
        list-style: circle;
        li {
            margin-left: 20px;
            font-size: 18px;
            line-height: 32px;
            color: $body-text;
        }
    }

    .footnote {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
    .logo-board {
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 60px;

        .partner {
            width: calc(25% - 20px);
            flex-shrink: 0;
            margin: 10px;
            background: #F9F9F9;

            img {
                width: 75%;
                height: auto;
                display: block;
                margin: 0 auto;
            };
        }
    }

    .more-partners {
        ul {
            margin-bottom: 40px;
        }
    }

    @include breakpoint(tablet){

        .logo-board {
            .partner {
                width: calc(50% - 20px);
            }
        }
    }

    @include breakpoint(mobile){
        .logo-board {
            .partner {
                width: 100%;
            }
        }
    }
}