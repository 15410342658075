@import "../responsive";

.promo-row {
    height: 640px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    
    .promo {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
        flex-shrink: 0;
        color: $white;
        text-align: center;
        align-items: center;
        align-content: center;
        // justify-content: center;
        padding-top: 90px;
        h3 {
            font-size: 42px;
            line-height: 74px;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 130px;
            padding: 0 40px;
        }

        &.about {
            background:url("../../assets/backgrounds/aboutUs.png") no-repeat top left;
        }
        &.pandemic {
            background:url("../../assets/backgrounds/globalPandemic.png") no-repeat top left;
        }
        .button {
            font-size: 18px;
            font-weight: normal;
            padding: 15px 30px;
            position: absolute;
            bottom: 80px;
        }
    }

    @include breakpoint(tablet){
        display: block;
        height: auto;
        .promo {
            height: 400px;
            width: 100%;
            background-size: contain;
            background-size: 100%;
            padding-top: 0;
            justify-content: center;
            position: relative;

            h3 {
                font-size: 30px;
                line-height: 37px;
                margin-bottom: 20px;
            }
    
            h4 {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 20px;
                padding: 0 20px;
            }
            .button {
                bottom: 30px;
            }

        }
    
    }

}