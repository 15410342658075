@import "../responsive";

.header-carousel {
    height:427px;
    width: 100%;
    position: relative;

    .carousel-container {
        z-index: 9;
        height: 100%;
        position:relative;
        .carousel-root {
            height: 100%;
            .carousel {
                height: 100%;
                .slider-wrapper {
                    height: 100%;
                    .slider {
                        height: 100%;
                    }

                }
            }
        }
        .content {
            height: 100%;
            padding: 100px 85px 0 85px;
            .info {
                .lead {
                    text-align: left;
                    color: $white;
                    h1, h2 {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        line-height: 40px;
                        color: $secondary-color1;
                    }
                    h2 {
                        color: $white;
                        font-size: 50px;
                    }
                    p {
                        width: 470px;
                        font-size: 22px;
                        line-height: 24px;
                        @include breakpoint(tablet){
                            width: 100%;
                        }
                    }

                    .button {
                        font-size: 18px;
                        margin-top: 30px;

                        &:first-of-type {
                            margin-right: 20px;
                        }
                    }

                }
            }

            @include breakpoint(tablet){
                padding: 50px 20px 0 20px;
                background-position: 60% !important;

                .info .lead {
                    h1, h2 {
                        font-size: 28px;
                    }

                    .button {
                        font-size: 15px;
                        margin-top: 110px;
                        padding: 15px;
                    }
                } 
            }
        }
        .slide {
            height: 100%;
        };
        .controls {
            width: 100%;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100px;
            padding: 0 85px;

            .pane {
                width: 100%;
                background: $white;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                align-items: center;

                > div {
                    flex-grow: 1;
                    text-align: center;
                }
                .learn-more a {
                    font-size: 16px;
                    line-height: 16px;
                    &:before {
                        padding-left:30px; /* Add a little space between text and arrow */
                        background: url("../../assets/icons/arrow-right.png") no-repeat top left;
                        content: "";
                    }
                }
                .slider-controls {
                    .left {
                        background: url("../../assets/icons/arrow-left.png") no-repeat top left;
                        width: 22px;
                        height: 20px;
                        float: left;
                        margin-right: 10px;
                    }
                    .right {
                        background: url("../../assets/icons/arrow-right.png") no-repeat top left;
                        width: 22px;
                        height: 20px;
                        float: left;
                    }
                    
                }
                .slider-status {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .item {
                        font-size: 12px;
                        line-height: 16px;
                        font-family:  Arial, Helvetica, sans-serif;
                        margin: 0 5px;
                        width: 30px;
                        height: 2px;
                        background: rgba(34,34,34, 0.2);
                        &.active {
                            background: rgba(34,34,34, 1);
                        }
                    }
                }
                .newsletter {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}