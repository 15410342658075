@import "../responsive";

.news-items {
    // margin: 80px 85px;
    .item {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-bottom: 40px;

        .date {
            font-size: 16px;
            line-height: 16px;
            color: #aaaaaa;
            margin-right: 30px;
        }
        .photo {
            width: 351px;
            margin-right: 30px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: auto;
                border: 0;
            }
        }
        .info {
            h4 {
                font-size: 16px;
                line-height: 16px;
                color: #aaaaaa;
                margin-bottom: 10px;
                text-transform: uppercase;
            }
            h5 {
                font-size: 18px;
                margin-bottom: 25px;

                a { 
                    color: $body-text;
                }
            }
            p {
                font-size: 18px;
                line-height: 26px;
                opacity: 0.6;
            }
            a {
                display: block;
                margin-top: 26px;
                &:after {
                    @extend %right-arrow;
                }
            }
        }
    }

    @include breakpoint(tablet) {
        margin: 20px;
        .item {
            display: block;

            .date {
                margin: 0;
                padding: 10px 0;
            }

            .photo {
                width: 100%;
                padding-bottom: 10px;
            } 
        }
    }
}