@import "../responsive";

.header {
    height: 330px; 

    h1 {
        color: $white;
        font-size: 48px;
        line-height: 64px;
        padding: 50px 0 0 85px;
        width: 80%;
    }

    @include breakpoint(tablet){

        background-position: center center;
        
        h1 {
            font-size: 34px;
            line-height: 44px;
            padding-left: 40px;
        }
    }
}