.photo-gallery {
    padding-left: 85px;

    .carousel-slider {
        overflow: visible;
        .slide {
            margin-right: 30px;
            img {
                height: 560px;
            }
        }
    }
    
    .status {
        margin-top: 40px;
        width: 100%;
        height: 2px;
        display: flex;
        flex-direction: row;
        .photo {
            flex-grow: 1;
            background: rgba(34,34,34, 0.2);
            &.active {
                background: rgba(34,34,34, 1);
            }
        }
    }

    .counter {
        margin-top: 20px;
        font-family:  Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 16px;
    }
}