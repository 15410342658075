@import "../responsive";

.about-partnerships {
    @extend %extra-padding;


    p {
        font-size: 18px;
        line-height: 32px;
        color: $body-text;
        margin-bottom: 30px;
    }
}